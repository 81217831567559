/* import __COLOCATED_TEMPLATE__ from './search-bar.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { getOwner } from '@ember/application';
import type Action from 'embercom/objects/global-search/actions/action';
import NavigationAction from 'embercom/objects/global-search/actions/navigation';
import type ApplicationInstance from '@ember/application/instance';
import { cached } from 'tracked-toolbox';
import SettingsRoutes from 'embercom/objects/global-search/routes/settings';
import AccountRoutes from 'embercom/objects/global-search/routes/account';
import type SearchableRoute from 'embercom/objects/global-search/routes/searchable-route';
import { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';
import { keepLatestTask } from 'ember-concurrency-decorators';
import { type TaskGenerator, timeout } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import { ref } from 'ember-ref-bucket';
import { type HotkeysMap } from 'embercom/services/inbox-hotkeys';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import NewSettingsRoutes from 'embercom/objects/global-search/routes/new-settings';

interface Args {
  isFromNewSettings?: boolean;
}

interface Signature {
  Args: Args;
  Blocks: any;
}

export default class SettingsSearchBar extends Component<Signature> {
  @service declare intercomEventService: any;
  @service declare inboxHotkeys: InboxHotkeys;
  @service declare appService: any;

  @tracked searchTerm = '';
  @tracked activeItemId?: string;
  @tracked isVisible = false;

  @tracked settingsRoute: SearchableRoute[] = SettingsRoutes[0].children!;

  readonly hotkeys: HotkeysMap;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (args.isFromNewSettings && this.appService.app.canSeeNewIASettingsHub) {
      this.settingsRoute = NewSettingsRoutes;
    }
    this.hotkeys = this.inboxHotkeys.hotkeysMap;
  }

  @ref('settings-search-modal') declare modal: HTMLDivElement;
  @ref('settings-search-toggle') declare searchToggle: HTMLDivElement;

  @cached
  get actions(): Action[] {
    let searchableRoutes = [...this.settingsRoute, ...AccountRoutes];
    return this.createNavigationActions(undefined, searchableRoutes);
  }

  get keys() {
    return [...this.hotkeys.CommandK.display];
  }

  @cached
  get flattenedActions(): Action[] {
    return this.actions
      .flatMap((action) => action.flattened())
      .filter((action) => action.isEnabled);
  }

  createNavigationActions(parent: Action | undefined, searchableRoutes: Array<SearchableRoute>) {
    let owner = getOwner(this) as ApplicationInstance;

    return searchableRoutes.map((searchableRoute) => {
      let navigationActionInputs = {
        id: `${searchableRoute.route}-${searchableRoute.name}`.replaceAll(/\s|\./g, '-'),
        name: searchableRoute.name,
        synonyms: searchableRoute.synonyms ?? [],
        route: searchableRoute.route,
        routeParams: searchableRoute.routeParams ?? {},
        featureFlags: searchableRoute.requiredFeatureFlags ?? [],
        hideOnFeatureFlags: searchableRoute.hideOnFeatureFlags ?? [],
        showOnlyOnCondition: searchableRoute.showOnlyOnCondition,
      };

      let searchAction = new NavigationAction(owner, parent, navigationActionInputs);

      if (searchableRoute.children) {
        this.createNavigationActions(searchAction, searchableRoute.children);
      }

      return searchAction;
    });
  }

  get filteredActions(): Action[] {
    if (!this.searchTerm) {
      return this.flattenedActions.filter((action) => action.isRoot || action.depth === 1);
    }

    let matches = this.flattenedActions.filter((action) => action.matches);

    return matches.sort((a, b) => {
      return a.priority - b.priority;
    });
  }

  get activeItem(): Action | undefined {
    if (this.activeItemId) {
      return this.filteredActions.find((action) => action.id === this.activeItemId);
    } else {
      return undefined;
    }
  }

  set activeItem(action: Action | undefined) {
    this.activeItemId = action?.id;
  }

  @action selectNext() {
    if (this.activeItem) {
      let index = this.filteredActions.findIndex((action) => action.id === this.activeItem?.id);

      if (index + 1 < this.filteredActions.length) {
        this.activeItem = this.filteredActions[index + 1];
      } else {
        this.activeItem = this.filteredActions.firstObject;
      }
    } else {
      this.activeItem = this.filteredActions.firstObject;
    }

    this.activeItem?.didBecomeActive();
  }

  @action selectPrevious() {
    if (this.activeItem) {
      let index = this.filteredActions.findIndex((action) => action.id === this.activeItem?.id);

      if (index - 1 >= 0) {
        this.activeItem = this.filteredActions[index - 1];
      } else {
        this.activeItem = this.filteredActions.lastObject;
      }
    } else {
      this.activeItem = this.filteredActions.lastObject;
    }

    this.activeItem?.didBecomeActive();
  }

  @action setActiveItem(action: Action) {
    this.activeItem = action;
  }

  @action async executeSelectedAction(action?: Action) {
    if (action) {
      if (action instanceof NavigationAction) {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'navigated',
          object: 'settings_search',
          search_term: this.searchTerm,
          selected_action: action.id,
          destination_route: action.route,
          selected_result: action.fullName,
        });
      }

      await action.onSelect();
      this.close();
    }
  }

  @action close(event?: KeyboardEvent) {
    // prevent the default cmd+k behavior. Firefox uses cmd+k to open the search bar
    event?.preventDefault();

    this.intercomEventService.trackAnalyticsEvent({
      action: 'closed',
      object: 'settings_search',
    });

    this.isVisible = false;
  }

  @action toggleVisibility(source = 'clicked', event?: KeyboardEvent) {
    if (this.isVisible) {
      this.close(event);
    } else {
      this.open(source, event);
    }
  }

  @action open(source = 'clicked', e?: KeyboardEvent) {
    // prevent the default cmd+k behavior. Firefox uses cmd+k to open the search bar
    e?.preventDefault();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'settings_search',
      opened_via: source,
    });

    this.searchTerm = '';
    this.flattenedActions.forEach((action) => {
      action.setQuery(this.searchTerm);
    });
    this.activeItem = undefined;
    this.isVisible = true;
  }

  @action updateSearch(term: string) {
    this.activeItem = undefined;

    this.searchTerm = term;

    this.flattenedActions.forEach((action) => {
      action.setQuery(this.searchTerm);
    });

    this.activeItem = this.filteredActions.firstObject;

    if (this.filteredActions.length === 1) {
      this.activeItem = this.filteredActions.firstObject;
    }

    taskFor(this.recordSearchEvent).perform();
  }

  @keepLatestTask *recordSearchEvent(): TaskGenerator<void> {
    yield timeout(ENV.APP._1S);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'searched',
      object: 'settings_search',
      search_term: this.searchTerm,
      results: this.filteredActions.length,
    });
  }

  @action handleDocumentClick(event: MouseEvent) {
    if (
      !this.modal.contains(event.target as Node) &&
      !this.searchToggle.contains(event.target as Node)
    ) {
      this.close();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'global-search/settings/search-bar': typeof SettingsSearchBar;
    'GlobalSearch::Settings::SearchBar': typeof SettingsSearchBar;
  }
}
